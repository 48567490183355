import React, {useState, useEffect} from "react";
const DetailsEstimation = ( { setSharedData, rideWicoId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
useEffect(() => {



    console.log('Fetching data...');
    // L'URL de votre API
    const apiUrl = `https://recette-pricing.wimotech.fr/api/v1/estimation/${rideWicoId}`; // Remplacez par votre URL d'API
    const apiKey = 'testKey01';
    // Fonction pour récupérer les données
    const fetchData = async () => {
        try {
            const response = await fetch(apiUrl,
                {
                    headers: {
                        'X-AUTH-TOKEN': `${apiKey}`,
                        'Content-Type': 'application/json+ld'
                    }
                });
            if (!response.ok) {
                console.log(response);
                throw new Error('Problème lors de la récupération des données');
            }
            console.log("URL", apiUrl);
            const result = await response.json();
            console.log(result);
            setSharedData(result);
            setData(result); // Mettre les données dans l'état
        } catch (err) {
            console.error(err);
            setError(err.message); // Si une erreur se produit, on la met dans l'état
        }
    };

    fetchData();
}, [rideWicoId]);


    return (
        <div>
            <h2>Détails de l'estimation de prix </h2>
            {data.description && <h4>Details de calcul:
                <span>
                    {Object.values(JSON.parse(data.description)).map(element => {
                        return  <li style={{ paddingLeft: '30px' }}> {'\u00A0\u00A0'}{element}</li>

                })}
            </span>

            </h4>}


            <h4>Estimation de prix : {data.price}</h4>
            <h4>Frais péage : {data.tollPrice}</h4>
            <h4>Departement : {data.department}</h4>
            <h4>Distance {data.distance} (km) </h4>
            <h4>Temps de trajet : {Math.ceil(data.duration *60) } (minutes)</h4>

        </div>
    );
};

export default DetailsEstimation;