import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

function RideWicoId() {

    const navigate = useNavigate();
    // Déclarer l'état pour le champ texte
    const [inputValue, setInputValue] = useState(''); // Valeur du champ texte

    const handleClick = () => {
        // Convertir la valeur en entier
        const intValue = parseInt(inputValue, 10);

        // Vérifier si la valeur est un entier valide
        if (!isNaN(intValue)) {

            navigate(`/estimation/${intValue}`);
        } else {
            alert('Veuillez entrer un nombre valide.');
        }
    };

    return (
        <div>

            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)} // Mettre à jour l'état du champ texte
                placeholder="Entrez le numéro de course Wico"
            />

            {/* Bouton pour envoyer la valeur */}
            <button onClick={handleClick}>Obtenir les détails d'estimation</button>
        </div>
    );
}

export default RideWicoId;