import React, {useState, useEffect} from 'react';
import RouteMap from "../../../components/Localization/RouteMap";
import DetailsEstimation from "./DetailsEstimation";
import {useParams} from "react-router-dom";
import './ReadEstimation.css';


const ReadEstimation = () => {

    const [sharedData, setSharedData] = useState("");
    const { id } = useParams();

    const [data, setData] = useState(null);
    useEffect(() => {
        // Ici vous pouvez faire une requête API avec l'id pour récupérer les données
        console.log('ID récupéré:', id);
        // Exemple de récupération de données (vous pouvez adapter en fonction de votre API)
        fetch(`/api/estimation/${id}`)
            .then((response) => response.json())
            .then((data) => setData(data));
    }, [id]);
    return (
        <div className="container">
            <div className="column left-column">
                <RouteMap sharedData={sharedData} />
            </div>
            <div className="column right-column">
                <DetailsEstimation setSharedData={setSharedData} rideWicoId= {id}/>
            </div>
        </div>

    );
};

export default ReadEstimation;