// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-map-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%; /* Moitié droite de l'écran */
    height: 60vh; /* 60% de la hauteur de l'écran */
}

.address-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background: #fff;
}

.address-inputs input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.suggestions {
    list-style: none;
    padding: 0;
    margin: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.suggestions li {
    padding: 5px;
    cursor: pointer;
}

.suggestions li:hover {
    background: #f0f0f0;
}

.map {
    flex-grow: 1; /* Prend toute la hauteur restante */
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Localization/RouteMap.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,oBAAoB;IACpB,WAAW,EAAE,6BAA6B;IAC1C,YAAY,EAAE,iCAAiC;AACnD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,wCAAwC;IACxC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY,EAAE,oCAAoC;IAClD,WAAW;AACf","sourcesContent":[".route-map-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: stretch;\n    width: 100%; /* Moitié droite de l'écran */\n    height: 60vh; /* 60% de la hauteur de l'écran */\n}\n\n.address-inputs {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    background: #fff;\n}\n\n.address-inputs input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n}\n\n.suggestions {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    background: white;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n}\n\n.suggestions li {\n    padding: 5px;\n    cursor: pointer;\n}\n\n.suggestions li:hover {\n    background: #f0f0f0;\n}\n\n.map {\n    flex-grow: 1; /* Prend toute la hauteur restante */\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
