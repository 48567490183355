import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import "./RouteMap.css";

const customIcon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/leaflet/1.7.1/images/marker-shadow.png",
    shadowSize: [41, 41],
});

const RouteMap = ({ onCoordinatesChange, sharedData }) => {
    const [startAddress, setStartAddress] = useState("");
    const [endAddress, setEndAddress] = useState("");
    const [startSuggestions, setStartSuggestions] = useState([]);
    const [endSuggestions, setEndSuggestions] = useState([]);
    const [startCoordinates, setStartCoordinates] = useState(null);
    const [endCoordinates, setEndCoordinates] = useState(null);
    const [route, setRoute] = useState([]);
    const mapRef = useRef(null);
    const [focusCenter, setFocusCenter] = useState([48.8566, 2.3522]);
    const NOMINATIM_URL = "https://nominatim.openstreetmap.org/search";
    // Fonction pour la gestion de la saisie d'adresse
    const handleAddressChange = async (value, setter, suggestionSetter) => {
        setter(value);
        if (value.length > 3) {
            try {
                const response = await axios.get(NOMINATIM_URL, {
                    params: {
                        q: value,
                        format: "json",
                        addressdetails: 1,
                        limit: 5,
                    },
                });
                suggestionSetter(response.data);
            } catch (error) {
                console.error("Error fetching address suggestions:", error);
            }
        } else {
            suggestionSetter([]);
        }
    };
    const refreshMap = () => {
        if (mapRef.current) {
            mapRef.current.setView(focusCenter, 14); // Recentrer avec les nouvelles coordonnées et un zoom de 14
        }
    };
    const handleSuggestionClick = (suggestion, setter, coordinateSetter, suggestionSetter) => {
        setter(suggestion.display_name);
        const coordinates = [parseFloat(suggestion.lat), parseFloat(suggestion.lon)];
        coordinateSetter(coordinates);
        suggestionSetter([]);
    };


    // Utilisation de useEffect pour centrer la carte dès que les coordonnées de départ sont définies
    useEffect(() => {

        if (startCoordinates && mapRef.current) {
            setFocusCenter(startCoordinates);
            mapRef.current.setView(focusCenter, 13);
        }
    }, [startCoordinates]); // Cette fonction se déclenche chaque fois que startCoordinates change

    useEffect(() => {
        if (startCoordinates && endCoordinates) {
            console.log("Coordinates changed:", startCoordinates, endCoordinates);
            if (onCoordinatesChange)
            {
                onCoordinatesChange( startCoordinates[0] ,startCoordinates[1] , endCoordinates[0] ??0, endCoordinates[1]??0);
            }

            calculateRoute();
        }
    }, [startCoordinates, endCoordinates]
    );
    useEffect(() => {
        if (sharedData) {
            console.log("Shared data changed:", sharedData);
            setStartCoordinates([sharedData.originPointLat, sharedData.originPointLng]);
            setEndCoordinates([sharedData.destinationPointLat, sharedData.destinationPointLng]);
            setFocusCenter([sharedData.originPointLat, sharedData.originPointLng]);
        refreshMap();
        }
    }, [sharedData]);

    const calculateRoute = async () => {
        if (startCoordinates && endCoordinates) {
            try {
                const OSRM_URL = `https://router.project-osrm.org/route/v1/driving/${startCoordinates[1]},${startCoordinates[0]};${endCoordinates[1]},${endCoordinates[0]}`;
                const response = await axios.get(OSRM_URL, {
                    params: { overview: "full", geometries: "geojson" },
                });
                if (response.data.routes && response.data.routes.length > 0) {
                    setRoute(response.data.routes[0].geometry.coordinates.map(([lon, lat]) => [lat, lon]));
                }
            } catch (error) {
                console.error("Error calculating route:", error);
            }
        }
    };

    return (

        <div className="route-map-container">

            {!sharedData && <div className="address-inputs">
                <input
                    type="text"
                    placeholder="Adresse de départ"
                    value={startAddress}
                    onChange={(e) => handleAddressChange(e.target.value, setStartAddress, setStartSuggestions)}
                />
                <ul className="suggestions">
                    {startSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() =>
                                handleSuggestionClick(suggestion, setStartAddress, setStartCoordinates, setStartSuggestions)
                            }
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>

                <input
                    type="text"
                    placeholder="Adresse d'arrivée"
                    value={endAddress}
                    onChange={(e) => handleAddressChange(e.target.value, setEndAddress, setEndSuggestions)}
                />
                <ul className="suggestions">
                    {endSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() =>
                                handleSuggestionClick(suggestion, setEndAddress, setEndCoordinates, setEndSuggestions)
                            }
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            </div> }

            <div className="map">
                <MapContainer
                    center={focusCenter} // Coordonnées initiales pour centrer la carte au départ
                    zoom={14}
                    style={{height: "100%", width: "100%"}}
                    key={focusCenter.toString()}
                    whenCreated={(map) => (mapRef.current = map)}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {startCoordinates && <Marker position={startCoordinates} icon={customIcon}/>}
                    {endCoordinates && <Marker position={endCoordinates} icon={customIcon}/>}
                    {route.length > 0 && <Polyline positions={route} color="blue"/>}
                </MapContainer>
            </div>
        </div>
    );
};

export default RouteMap;
