import React, { useState } from 'react';
import axios from 'axios';
import RouteMap from "../../components/Localization/RouteMap";

function CreateEstimation() {

    const [coordinates, setCoordinates] = useState(null);
    const updateCoordinates = (departurelat, departrelng, arrivallat, arrivallng) => {

        setCoordinates({ departurelat, departrelng, arrivallat, arrivallng });
        formData.origin.lat = departurelat;
        formData.origin.lng = departrelng;
        formData.destination.lat = arrivallat;
        formData.destination.lng = arrivallng;
    };
    // Définition des états pour chaque champ
    const [formData, setFormData] = useState({
        origin: { lat: '45.760845343109196', lng: '4.844507179296919' },
        destination: { lat: '45.76980115198197', lng: '5.017907326181343' },
        departureDate: '2024-08-23T14:48:40.912Z',
        passengersCount: 1,
        luggageCount: 0
    });

    // Fonction pour gérer la saisie dans les champs
    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
        if (field) {
            setFormData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [field]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    // Fonction pour valider les champs
    const validateForm = () => {
        // Vérifier que les champs sont remplis et sont des nombres
        if (
            isNaN(parseFloat(formData.origin.lat)) ||
            isNaN(parseFloat(formData.origin.lng)) ||
            isNaN(parseFloat(formData.destination.lat)) ||
            isNaN(parseFloat(formData.destination.lng)) ||
            isNaN(parseInt(formData.passengersCount)) ||
            isNaN(parseInt(formData.luggageCount))
        ) {
            alert('Veuillez remplir tous les champs obligatoires avec des valeurs valides.');
            return false;
        }

        // Vérifier que la date est valide
        if (isNaN(new Date(formData.departureDate).getTime())) {
            alert('La date est invalide.');
            return false;
        }
        formData.passengersCount = parseInt(formData.passengersCount);
        formData.luggageCount = parseInt(formData.luggageCount);

        return true;
    };

    // Fonction pour envoyer la requête
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Si les données sont valides, envoyer la requête
        if (validateForm()) {
            const headers = {
                'X-AUTH-TOKEN': 'testKey01',  // Par exemple, un token d'authentification
                'Content-Type': 'application/ld+json',  // **Ne pas spécifier** de Content-Type, Axios l'ajustera automatiquement pour multipart/form-data
            };
            try {
               console.log(formData);

                const response = await axios.post('https://recette-pricing.wimotech.fr/api/v1/price/estimator',
                    JSON.stringify(formData),
                    { headers: headers });
                alert('Données envoyées avec succès!');
                console.log(response.data); // Afficher la réponse du serveur
            } catch (error) {
                console.error('Erreur lors de l\'envoi des données :', error);
                alert('Une erreur est survenue lors de l\'envoi des données.');
            }
        }
    };

    return (

        <div>
           <RouteMap onCoordinatesChange={updateCoordinates} />
            <form onSubmit={handleSubmit}>
                <fieldset>
                    <legend>Adresse de Départ (Longitude, Latitude)</legend>
                    <div>
                        <label>Longitude (obligatoire) :</label>
                        <input
                            type="number"
                            name="origin.lng"
                            value={formData.origin.lng}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Latitude (obligatoire) :</label>
                        <input
                            type="number"
                            name="origin.lat"
                            value={formData.origin.lat}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Adresse d'Arrivée (Longitude, Latitude)</legend>
                    <div>
                        <label>Longitude (obligatoire) :</label>
                        <input
                            type="number"
                            name="destination.lng"
                            value={formData.destination.lng}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Latitude (obligatoire) :</label>
                        <input
                            type="number"
                            name="destination.lat"
                            value={formData.destination.lat}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </fieldset>
                <div>
                    <label>Nombre de passagers :</label>
                    <input
                        type="number"
                        name="passengersCount"
                        value={formData.passengersCount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Nombre de bagages :</label>
                    <input
                        type="number"
                        name="luggageCount"
                        value={formData.luggageCount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Date et Heure (obligatoire) :</label>
                    <input
                        type="datetime-local"
                        name="departureDate"
                        value={formData.departureDate}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit"> Estimer </button>
            </form>
        </div>
    );
}

export default CreateEstimation;