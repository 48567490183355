// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-wimova {
    background-color: #4CAF50; /* Vert */
    border: none;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-wimova:hover {
    background-color: #45a049;
    transform: translateY(-3px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.button-wimova:active {
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/button.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,SAAS;IACpC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gCAAgC;IAChC,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,0CAA0C;AAC9C;;AAEA;IACI,wBAAwB;IACxB,wCAAwC;AAC5C","sourcesContent":[".button-wimova {\n    background-color: #4CAF50; /* Vert */\n    border: none;\n    color: white;\n    padding: 12px 24px;\n    font-size: 16px;\n    font-weight: bold;\n    text-transform: uppercase;\n    border-radius: 8px;\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.button-wimova:hover {\n    background-color: #45a049;\n    transform: translateY(-3px);\n    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);\n}\n\n.button-wimova:active {\n    transform: translateY(0);\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
