// LimitSetting.js
import React from "react";
import "./LimitSetting.css";  // On va utiliser ce fichier CSS pour la mise en page

function LimitSetting() {
    return (
        <div className="container">
            {/* Partie gauche divisée en deux sections égales */}
            <div className="left">
                <div className="left-top">Plafonds</div>
                <div className="left-bottom">Approches forfaitaires</div>
            </div>

            {/* Partie droite non divisée */}
            <div className="right">Clients</div>
        </div>
    );
}

export default LimitSetting;