// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* page-container : Container principal qui divise l'écran en 2 parties */
.page-container {
  display: flex;
  height: 100vh; /* Hauteur de l'écran complète */
  width: 100%;
}

/* Partie gauche (50% de l'écran) */
.left-side {
  flex: 1 1; /* La partie gauche prend 50% de l'écran */
  background-color: #f4f4f4;
  padding: 20px;
  box-sizing: border-box;
}

/* Partie droite (50% de l'écran) */
.right-side {
  flex: 1 1; /* La partie droite prend 50% de l'écran */
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

/* Les contenus internes pour mieux organiser les sections */
.left-content, .right-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Les titres et paragraphes */
.left-content h1, .right-content h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.left-content p, .right-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,yEAAyE;AACzE;EACE,aAAa;EACb,aAAa,EAAE,gCAAgC;EAC/C,WAAW;AACb;;AAEA,mCAAmC;AACnC;EACE,SAAO,EAAE,0CAA0C;EACnD,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA,mCAAmC;AACnC;EACE,SAAO,EAAE,0CAA0C;EACnD,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB;;AAEA,4DAA4D;AAC5D;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA,8BAA8B;AAC9B;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["/* page-container : Container principal qui divise l'écran en 2 parties */\n.page-container {\n  display: flex;\n  height: 100vh; /* Hauteur de l'écran complète */\n  width: 100%;\n}\n\n/* Partie gauche (50% de l'écran) */\n.left-side {\n  flex: 1; /* La partie gauche prend 50% de l'écran */\n  background-color: #f4f4f4;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n/* Partie droite (50% de l'écran) */\n.right-side {\n  flex: 1; /* La partie droite prend 50% de l'écran */\n  background-color: #fff;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n/* Les contenus internes pour mieux organiser les sections */\n.left-content, .right-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n/* Les titres et paragraphes */\n.left-content h1, .right-content h2 {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 10px;\n}\n\n.left-content p, .right-content p {\n  font-size: 16px;\n  color: #666;\n  line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
