import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateEstimation from "./Views/Estimation/CreateEstimation";
import BrowseEstimations from "./Views/Estimation/BrowseEstimations";
import ReadEstimation from "./Views/Estimation/ReadEstimation/ReadEstimation";
import './App.css';
import './button.css';
import { loginRequest } from "./auth-config";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";

const WrappedView = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleRedirect = () => {
        instance.loginRedirect({ ...loginRequest, prompt: 'create' }).catch(console.log);
    };

    const handleLogout = () => instance.logout();

    return (
        <div className="App">
            <AuthenticatedTemplate>
                {activeAccount && (
                    <div className="page-container">
                        <div className="left-side">
                            <div className="left-content">
                                <h1>Création d'une estimation</h1>
                                <CreateEstimation />
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="right-content">
                                <h1>Historique</h1>
                                <BrowseEstimations />
                            </div>
                        </div>
                    </div>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div style={{textAlign: "center"}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh", // Hauteur de l'écran
                    }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/LOGO2.png`}
                        />
                    </div>

                    <button className="button-wimova" onClick={handleRedirect}>Se connecter</button>
                </div>

            </UnauthenticatedTemplate>
        </div>
    );
};

const App = ({instance}) => (
    <MsalProvider instance={instance}>
        <Router>
            <Routes>
                <Route path="/" element={<WrappedView />} />
                <Route path="/estimation/:id" element={<ReadEstimation />} />
            </Routes>
        </Router>
    </MsalProvider>
);

export default App;