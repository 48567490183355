// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles spécifiques au composant */
.table {
    width: 100%;
    border-collapse: collapse;
}

.cell {
    padding: 10px;
    border: 1px solid #ddd;
}

.header {
    background-color: #f4f4f4;
    padding: 10px;
}

table {
    width: 90%;
    border-collapse: collapse;
}

td {
    padding: 10px;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Estimation/BrowseEstimation.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":["/* styles spécifiques au composant */\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.cell {\n    padding: 10px;\n    border: 1px solid #ddd;\n}\n\n.header {\n    background-color: #f4f4f4;\n    padding: 10px;\n}\n\ntable {\n    width: 90%;\n    border-collapse: collapse;\n}\n\ntd {\n    padding: 10px;\n    border: 1px solid #ddd;\n}\n\nth {\n    background-color: #f4f4f4;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
