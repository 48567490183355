// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    height: 100vh; /* Occupe toute la hauteur de l'écran */
}

.column {
    flex: 1 1; /* Chaque colonne prend 50% de la largeur */
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-column {
    background-color: #f0f0f0; /* Couleur de la colonne gauche */
}

.right-column {
    background-color: #e0e0e0; /* Couleur de la colonne droite */
}`, "",{"version":3,"sources":["webpack://./src/Views/Estimation/ReadEstimation/ReadEstimation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa,EAAE,uCAAuC;AAC1D;;AAEA;IACI,SAAO,EAAE,2CAA2C;IACpD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;AAChE;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;AAChE","sourcesContent":[".container {\n    display: flex;\n    height: 100vh; /* Occupe toute la hauteur de l'écran */\n}\n\n.column {\n    flex: 1; /* Chaque colonne prend 50% de la largeur */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.left-column {\n    background-color: #f0f0f0; /* Couleur de la colonne gauche */\n}\n\n.right-column {\n    background-color: #e0e0e0; /* Couleur de la colonne droite */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
