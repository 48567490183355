// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LimitSetting.css */


.container {
    display: flex;
    height: 100vh;
}


.left {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.left-top, .left-bottom {
    flex: 1 1;
    padding: 20px;
    border: 1px solid #ccc;
}


.right {
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f4f4f4;
}`, "",{"version":3,"sources":["webpack://./src/Views/Settings/Limit/LimitSetting.css"],"names":[],"mappings":"AAAA,qBAAqB;;;AAGrB;IACI,aAAa;IACb,aAAa;AACjB;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;AAC1B;;;AAGA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":["/* LimitSetting.css */\n\n\n.container {\n    display: flex;\n    height: 100vh;\n}\n\n\n.left {\n    display: flex;\n    flex-direction: column;\n    width: 50%;\n}\n\n.left-top, .left-bottom {\n    flex: 1;\n    padding: 20px;\n    border: 1px solid #ccc;\n}\n\n\n.right {\n    width: 50%;\n    padding: 20px;\n    border: 1px solid #ccc;\n    background-color: #f4f4f4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
