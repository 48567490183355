import React, {useState, useEffect} from "react";
import './BrowseEstimation.css';
import RideWicoId from './ReadEstimation/RideWicoId';
const BrowseEstimations = () => {
// Utilisation de useEffect pour faire la requête API lors du montage du composant
// Déclaration de l'état pour stocker les données
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

// Effect pour récupérer les données de l'API
    useEffect(() => {
        console.log('Fetching data...');
        // L'URL de votre API
        const apiUrl = 'https://recette-pricing.wimotech.fr/api/v1/estimations'; // Remplacez par votre URL d'API
        const apiKey = 'testKey01';
        // Fonction pour récupérer les données
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl,
                    {
                        headers: {
                            'X-AUTH-TOKEN': `${apiKey}`,
                            'Content-Type': 'application/json+ld'
                        }
                    });
                if (!response.ok) {
                    console.log(response);
                    throw new Error('Problème lors de la récupération des données');
                }
                const result = await response.json();
                console.log(result);

                setData(result['hydra:member']); // Mettre les données dans l'état
                setLoading(false); // Marquer le chargement comme terminé
            } catch (err) {
                console.error(err);
                setError(err.message); // Si une erreur se produit, on la met dans l'état
                setLoading(false); // Marquer le chargement comme terminé
            }
        };

        fetchData();
    }, []); // [] signifie que cet effect se lance une seule fois au montage du composant

// Si les données sont en cours de chargement
    if (loading) {
        return <div>Chargement...</div>;
    }

// Si une erreur s'est produite
    if (error) {
        return <div>Erreur: {error}</div>;
    }

// Affichage du tableau
    return (

        <div>
            <div>
                <h2> Obtenir les informations d'une course</h2>
                <RideWicoId/>
            </div>

            <h3>Tableau de 5 dernières estimations</h3>
            <table border="1">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>DispatchId</th>
                    <th>Description</th>
                    <th>Prix total</th>
                    <th>Péages</th>
                </tr>
                </thead>
                <tbody>
                {data.map(estimation => (
                    <tr>
                        <td>{estimation.id}</td>
                        <td>{estimation.rideExternalId}</td>
                        <td>
                        <span style={{whiteSpace: 'pre-line'}}>
                            {Object.values(JSON.parse(estimation.description)).join('\n')} {/* Utilisation du retour à la ligne avec \n */}
                        </span>
                        </td>
                        <td>{estimation.price}</td>
                        <td>{estimation.tollPrice}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};
export default BrowseEstimations;